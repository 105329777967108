




















































import { Component, Prop, Vue } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import camelCaseKeys from 'camelcase-keys'
import axios from 'axios'

@Component({
  components: {
    TrashCan16
  }
})
export default class ServiceSurveys extends Vue {
  @Prop({ default: 'service' }) kind!: ServiceMessageKind
  surveys = []

  created () {
    if (this.kind === 'create') {
      axios.get('/service/survey')
        .then(response => {
          this.surveys = camelCaseKeys(response.data.data, { deep: true })
        })
    } else if (this.kind === 'service') {
      this.fetchServiceSurveys()
    }
    this.surveys = []
  }

  fetchServiceSurveys () {
    axios.get(`/service/${this.$route.params.id}/survey`)
      .then(response => {
        this.surveys = camelCaseKeys(response.data.data, { deep: true })
        this.$emit('hasSurveys', !!this.surveys.length)
      })
  }

  toggleModal (modalName: string) {
    this.$data[modalName] = !this.$data[modalName]
  }
}
