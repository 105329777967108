
































import { Component, Mixins } from 'vue-property-decorator'
import Account16 from '@carbon/icons-vue/es/account/16'

import PermissionsMixin from '@/mixins/permissionsMixin'
import ServiceSurveys from '@/partials/tables/services/Surveys.vue'

@Component({
  components: {
    Account16,
    ServiceSurveys
  }
})
export default class ServiceSurveysIndex extends Mixins(PermissionsMixin) {

}
